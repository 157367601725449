import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { httpsCallable } from "@firebase/functions";
import { functions } from "../../firebaseConfig";

const PayPalDonationButton = ({ user, setUser, setShowCommentModal, setShowModal }: any) => {
  const {t} = useTranslation();
  const [amount, setAmount] = useState(200);
  const [blockPayment, setBlockPayment] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('purchase_amount', '200')
  }, [])

  useEffect(() => {
    if (user.amount) {
      sessionStorage.setItem('amount', user.amount);
    } else {
      sessionStorage.setItem('amount', '0');
    }

  }, [user])

  useEffect(() => {
    if (amount < 20) {
      setBlockPayment(true);
    } else {
      setBlockPayment(false);
    }
  }, [amount])

  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: sessionStorage.getItem('purchase_amount') || 200,
        },
      }],
    });
  };

  const handleApprove = (data: any, actions: any) => {
    const myFunction = httpsCallable(functions, 'paypalWebhook');
    return actions.order.capture().then(async (details: any) => {
      try {
        await myFunction({
          orderId: data.orderID,
          payer: details.payer,
          status: details.status,
          userId: localStorage.getItem('wallet_id')
        });

        const addToAmount = user.amount ?
          //@ts-ignore
          Number(sessionStorage.getItem('amount'), 10) + Number((sessionStorage.getItem('purchase_amount') / 0.05), 10) :
          //@ts-ignore
          Number(sessionStorage.getItem('purchase_amount') / 0.05);

        setUser({
          ...user,
          amount: addToAmount,
          purchase_amount: sessionStorage.getItem('purchase_amount'),
        });

        setShowCommentModal(true);
        setShowModal(false);
      } catch (e) {
        console.error('handleApprove:', e);
      }
    });
  };

  return (
    <div>
      <div style={{marginBottom: 10}}>
        <div style={{marginBottom: 10}}>
          {t('paypal_donation_button.purchase_amount_input')}
        </div>
        <span>$ </span>
        <input
          type="number"
          value={amount}
          onChange={(e: any) => {
            setAmount(e.target.value)
            sessionStorage.setItem('purchase_amount', e.target.value)
          }}
          placeholder="0"
          style={{width: 80}}
        />
        <span> MXN = </span>
        <span style={{fontWeight: 'bold'}}>{(amount / 0.05).toLocaleString()}</span>
        <span style={{fontWeight: 'bold'}}> LB</span>

        {blockPayment && (
          <div style={{marginBottom: 10}}>
            <span style={{fontWeight: 'bold', color: 'red'}}>{t('paypal_donation_button.amount_error')}</span>
          </div>
        )}
      </div>
      <PayPalScriptProvider options={{ clientId: 'Ac0fL3pAjFLH3t4-rFR8tATKmNNZyv_TgvY8L60MNF8p8RuLXYCBf1d8jISSfEHe3teLetiTK_PCI85G', currency: 'MXN' }}>
        <PayPalButtons disabled={blockPayment} createOrder={createOrder} onApprove={handleApprove} />
      </PayPalScriptProvider>
    </div>
  );
};

export default PayPalDonationButton;
