import React  from "react";
import { useTranslation } from "react-i18next";
import VideoGrid from "../../components/VideoGrid";

const Staking = () => {
  const {t} = useTranslation();

  return (
    <div className="CountDown">
      <div className="CountDown-text">
        <div>
          <span className="Header-title">{t('staking.title')}</span>
        </div>
        <div className="Flex" style={{justifyContent: 'center', overflowY: 'hidden'}}>
          <div style={{textAlign: 'left', maxWidth: '80%', backgroundColor: 'rgba(0,0,0,0.4)', padding: 10, borderRadius: 20}}>
            <div>
              <span className="Header-text" style={{fontWeight: "bold", fontStyle: 'italic'}}>{t('staking.pre_sale', {phase: 1})}</span>
            </div>
            <div>
              <span style={{color: '#fff', fontWeight: "bold", fontStyle: 'italic'}}>👉 {t('staking.pre_sale1_date')}</span>
            </div>
            <div style={{color: '#fff', fontWeight: 'bold'}}>
              <p>
                😲 {t('staking.pre_sale1_item1')}
              </p>
              <p>
                🤫 {t('staking.pre_sale1_item2')}
              </p>
              <p>
                🤭 {t('staking.pre_sale1_item3')}
              </p>
              <p>
                🤔 {t('staking.pre_sale1_item4')}
              </p>
            </div>
            <div>
              <span className="Header-text" style={{fontWeight: "bold", fontStyle: 'italic', color: '#e8bd5c'}}>{t('staking.pre_sale', {phase: 2})}</span>
            </div>
            <div>
              <span style={{color: '#e8bd5c', fontWeight: "bold", fontStyle: 'italic'}}>👉 {t('staking.pre_sale2_date')}</span>
            </div>
            <div style={{color: '#e8bd5c', fontWeight: 'bold'}}>
              <p>
                😲 {t('staking.pre_sale2_item1')}
              </p>
              <p>
                🤫 {t('staking.pre_sale2_item2')}
              </p>
              <p>
                🤭 {t('staking.pre_sale2_item3')}
              </p>
            </div>
            <div>
              <span className="Header-text" style={{fontWeight: "bold", fontStyle: 'italic'}}>{t('staking.pre_sale', {phase: 3})}</span>
            </div>
            <div>
              <span style={{color: '#fff', fontWeight: "bold", fontStyle: 'italic'}}>👉 {t('staking.pre_sale3_date')}</span>
            </div>
            <div style={{color: '#fff', fontWeight: 'bold'}}>
              <p>
                😲 {t('staking.pre_sale3_item1')}
              </p>
              <p>
                🤫 {t('staking.pre_sale3_item2')}
              </p>
              <p>
                🤭 {t('staking.pre_sale3_item3')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <VideoGrid />
    </div>
  );
}

export default Staking;
