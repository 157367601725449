import React from 'react';
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from "./components/Main";
import Server from "./components/Server";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Router>
          <Routes>
            <Route path="/:language?" element={<Main />} />
            <Route path="/server/:id/:language?" element={<Server />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </div>
  );
}

export default App;
